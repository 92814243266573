import React from 'react';
import { Helmet } from 'react-helmet';

import ProjectPreviewView from '../../components/ProjectPreviewView';
import ProjectLayout from '../../layout/ProjectLayout';

interface ExtendedLocation extends Location {
  state?: { examine: boolean; bidRequest: boolean; closed: boolean };
}
const ProjectPreview = ({ location }: { location: ExtendedLocation }): React.ReactElement => {
  const requestId = parseInt(location.hash.replace('#', ''));

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <ProjectLayout>
        <ProjectPreviewView
          requestId={requestId}
          examine={location.state?.examine ? location.state.examine : false}
          bidRequest={location.state?.bidRequest ? location.state.bidRequest : false}
          closed={location.state?.closed ? location.state.closed : false}
        ></ProjectPreviewView>
      </ProjectLayout>
    </>
  );
};

export default ProjectPreview;
