import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
// import { SettingOutlined } from '@ant-design/icons';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { navigate } from 'gatsby';

import { initialize } from '../actions/userActions';
import Header from '../components/ConsultantHeader';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import LoadingLogo from '../components/LoadingLogo';
import Notification from '../components/Notification';
import { AppState } from '../reducers';
import { loadingEndAction } from '../reducers/loadingReducer';
import debug from '../utils/debug';

const styles = (): StyleRules =>
  createStyles({
    noAdmin: {
      height: '50rem',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    noAdminItem: {
      margin: '1%',
    },

    container: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      width: '100%',
    },
    nav: {
      width: '210px',
      padding: '20px',
      textAlign: 'left',
      background: '#673ab7',
    },
    content: {
      margin: '0 auto',
      paddingTop: '64px',
      width: '100%',
      border: '1px solid #ccc',
    },
  });

const AdminLayout = ({
  classes,
  children,
  verificationHide = false,
}: Props): React.ReactElement => {
  verificationHide;

  debug('Loading admin layout');
  const { id: uid, userType } = useSelector((appState: AppState) => appState.user);
  //   const { id: adminId, loaded: adminLoaded } = useSelector((appState: AppState) => appState.admin);
  const isLoading = useSelector((state: AppState) => state.loading.isLoading);
  const { isVisible, severity, message } = useSelector((state: AppState) => state.notification);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialize(userType));
    dispatch(loadingEndAction());
  }, []);

  const NoUser = (
    <Grid container direction="column" className={classes.noAdmin}>
      <Grid item className={classes.noAdminItem}>
        <Typography>您还未登录，请先登录</Typography>
      </Grid>
      <Grid item className={classes.noAdminItem}>
        <Button
          onClick={() => {
            navigate(`/consultant/login`);
          }}
          variant="contained"
          color="primary"
          style={{
            width: '20%',
          }}
        >
          登录
        </Button>
      </Grid>
    </Grid>
  );

  if (!uid) {
    return <>{NoUser}</>;
  }

  if (uid && children) {
    return (
      <>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <link rel="stylesheet" href="https://fonts.loli.net/icon?family=Material+Icons" />
        </Helmet>
        <div style={{ position: 'fixed', zIndex: '9999' }}>
          <Header projectDisplays={true} />
        </div>

        <div className={classes.container}>
          <div className={classes.content}>{children}</div>
        </div>

        <Footer />
        {isLoading && <Loading />}
        {isVisible && <Notification isVisible={isVisible} severity={severity} message={message} />}
      </>
    );
  }

  return <LoadingLogo />;
};

export interface Props extends WithStyles<typeof styles> {
  children?: React.ReactElement | React.ReactElement[];
  verificationHide?: boolean;
}

export default withStyles(styles)(AdminLayout);
